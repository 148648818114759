import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  Unsubscribe,
  where
} from "firebase/firestore";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { db } from "../api/firebaseApi";
import { GroupedDocument, SuperGroupedDocument } from "../helpers/helpers";
import {
  addNewDocument,
  deleteDocument,
  setGroupedDocuments as setSuperGroupedDocumentsSlice,
  updateDocument
} from "../redux/documents/documentSlice";
import { generateSingleDocumentUrl } from "../redux/documents/documentsThunk";
import { VisaDocumentType } from "../redux/documents/types";
import { store, useTypedDispatch } from "../redux/store";
import { DataDocs } from "../types/tables-data";
import { useDocumentUrlRetry } from "./useDocumentUrlRetry";
import {
  DocumentOrderingService,
  DocumentOrder
} from "../services/documentOrderingService";

type PathWithType = {
  path: string;
  super_class: string;
  documentType: VisaDocumentType;
};

const useDocuments = (paths: PathWithType[], refetch?: boolean) => {
  const pendingDocumentIds = useRef<Set<string>>(new Set());
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const { id, visaType } = useParams();
  const dispatch = useTypedDispatch();
  const { scheduleRetry, clearRetry } = useDocumentUrlRetry();
  const orderingService = useRef<DocumentOrderingService | null>(null);
  const hasCustomOrder = useRef(false);
  const customOrder = useRef<DocumentOrder[] | null>(null);

  const [groupedDocuments, setGroupedDocuments] = useState<
    GroupedDocument[] | null
  >(null);

  const [superGroupedDocuments, setSuperGroupedDocuments] = useState<
    SuperGroupedDocument[] | null
  >(null);

  useEffect(() => {
    if (visaType) {
      orderingService.current = DocumentOrderingService.getInstance(visaType);
    }
  }, [visaType]);

  const checkIfDocumentIsNew = (document: DataDocs) => {
    const uploadedDate = document.uploadDate;

    // Backward compatibility check with older formats
    const uploadDateInMillis =
      uploadedDate instanceof Timestamp
        ? uploadedDate.toMillis()
        : typeof uploadedDate === "number"
        ? uploadedDate
        : null;

    const isNew =
      uploadDateInMillis! + 30 * 1000 > Timestamp.now().toMillis() &&
      document.isNew;
    return isNew;
  };

  // Simplify updateFirestore to always write
  const updateFirestore = useCallback(
    async (id: string, documentOrder: DocumentOrder[]) => {
      const docRef = doc(db, `/documents/${id}`);
      await setDoc(
        docRef,
        { documentsTableOrder: documentOrder },
        { merge: true }
      );
    },
    []
  );

  const updateCurrentOrder = useCallback(
    (_superGroupedDocuments: SuperGroupedDocument[]) => {
      if (!id || !_superGroupedDocuments || !orderingService.current) return;

      // During processing, preserve custom order if it exists
      if (
        DocumentOrderingService.hasProcessingDocuments(_superGroupedDocuments)
      ) {
        const orderedDocs =
          hasCustomOrder.current && customOrder.current
            ? orderingService.current.applySavedOrder(
                _superGroupedDocuments,
                customOrder.current
              )
            : orderingService.current.applyDefaultOrder(_superGroupedDocuments);
        dispatch(setSuperGroupedDocumentsSlice(orderedDocs));
        return;
      }

      const firestoreDocumentTableOrder =
        DocumentOrderingService.convertToFirestoreDocumentTableOrder(
          _superGroupedDocuments
        );
      customOrder.current = firestoreDocumentTableOrder;
      hasCustomOrder.current = true;
      dispatch(setSuperGroupedDocumentsSlice(_superGroupedDocuments));
      updateFirestore(id, firestoreDocumentTableOrder);
    },
    [id, dispatch, updateFirestore]
  );

  // Helper function to handle document URL generation
  const handleDocumentUrl = useCallback(
    (document: DataDocs) => {
      const state = store.getState();
      const isUrlAlreadyGenerated = state.document.groupedDocuments?.some(
        (group) =>
          group.super_class === document.super_class &&
          group.groups?.some((subGroup) =>
            subGroup.subrows?.some(
              (doc) => doc.id === document.id && doc.docUrl
            )
          )
      );

      if (
        !isUrlAlreadyGenerated &&
        document.id &&
        !pendingDocumentIds.current.has(document.id)
      ) {
        pendingDocumentIds.current.add(document.id);
        if (
          document.pdf_file_path &&
          document.criterion &&
          document.super_class
        ) {
          dispatch(
            generateSingleDocumentUrl({
              criterion: document.criterion,
              filePath: document.pdf_file_path,
              id: document.id,
              super_class: document.super_class
            })
          );
        } else if (document.id) {
          scheduleRetry(document.id);
        }
      }
    },
    [dispatch, scheduleRetry]
  );

  const groupDocumentsBySuperClass = (documents: DataDocs[]) => {
    if (!orderingService.current) return [];
    return orderingService.current.groupAndOrderDocuments(documents);
  };

  // Fetch saved order from Firestore
  const fetchOrderFromFirestore = useCallback(async () => {
    if (!id) return [];
    const documentPath = `/documents/${id}/`;
    const orderDocumentReference = doc(db, documentPath);
    const document = await getDoc(orderDocumentReference);
    return document.exists()
      ? (document.data()?.documentsTableOrder as DocumentOrder[])
      : [];
  }, [id]);

  useEffect(() => {
    const unsubscribeList: Unsubscribe[] = [];
    const fetchedDocumentIds = new Set<string>();

    const fetchAndListen = async () => {
      const documentPromises = paths.map((path) => {
        const collectionRef = collection(db, path.path);
        const filteredQuery = query(
          collectionRef,
          where("isDeleted", "==", false)
        );

        return getDocs(filteredQuery).then((querySnapshot) => {
          const initialDocuments = querySnapshot.docs.map((doc) => {
            const isNew = checkIfDocumentIsNew(doc.data() as DataDocs);

            return {
              id: doc.id,
              docRef: doc.ref.path,
              isDeleted: doc.data().isDeleted ?? false,
              super_class:
                doc.data().super_class === null ||
                doc.data().super_class === "" ||
                "Processing",
              criterion: doc.data().criterion || "Processing",
              documentType: path.documentType,
              collectionPath: path.path,
              ...doc.data(),
              isNew
            } as DataDocs;
          }) as DataDocs[];

          initialDocuments.forEach((doc) => fetchedDocumentIds.add(doc.id!));

          return initialDocuments;
        });
      });

      const allDocuments = (await Promise.all(documentPromises)).flat();

      const superGrouped = groupDocumentsBySuperClass(allDocuments);
      let sortedSuperGroupedDocs = superGrouped;

      const fetchedOrder = await fetchOrderFromFirestore();
      if (fetchedOrder.length !== 0 && orderingService.current) {
        hasCustomOrder.current = true;
        customOrder.current = fetchedOrder;
        sortedSuperGroupedDocs = orderingService.current.applySavedOrder(
          sortedSuperGroupedDocs,
          fetchedOrder
        );
      }

      setSuperGroupedDocuments(sortedSuperGroupedDocs);
      dispatch(setSuperGroupedDocumentsSlice(sortedSuperGroupedDocs));
      setIsLoadingDocuments(false);

      const documentsWithNoPdf = allDocuments.filter(
        (doc) => !doc.pdf_file_path
      );

      documentsWithNoPdf.forEach((doc) => {
        scheduleRetry(doc.id!);
      });

      paths.forEach((path) => {
        const collectionRef = collection(db, path.path);
        const filteredQuery = query(
          collectionRef,
          where("isDeleted", "==", false)
        );

        const unsubscribe = onSnapshot(filteredQuery, (snapshot) => {
          const changes = snapshot.docChanges();
          const updates: { type: string; document: DataDocs }[] = [];

          changes.forEach((change) => {
            const isNew = checkIfDocumentIsNew(change.doc.data() as DataDocs);

            const document = {
              ...change.doc.data(),
              docRef: change.doc.ref.path,
              id: change.doc.id,
              super_class: change.doc.data().super_class,
              criterion: change.doc.data().criterion,
              isDeleted: change.doc.data().isDeleted ?? false,
              isNew
            } as DataDocs;

            updates.push({ type: change.type, document });
          });

          updates.forEach(({ type, document }) => {
            const superClassDetected =
              document.super_class || document.super_class !== "";

            if (type === "added" && !fetchedDocumentIds.has(document.id!)) {
              dispatch(addNewDocument(document));
            } else if (type === "modified" && !document.isProcessing) {
              if (document.isDeleted === true) {
                dispatch(deleteDocument(document));
              } else if (
                superClassDetected &&
                document.criterion &&
                document.filePath &&
                document.id &&
                document.super_class
              ) {
                dispatch(updateDocument(document));
                handleDocumentUrl(document);
              }
            } else if (type === "removed") {
              dispatch(deleteDocument(document));
            }
          });

          const currentState = store.getState().document.groupedDocuments;
          if (currentState && orderingService.current) {
            let orderedDocs;
            if (hasCustomOrder.current && customOrder.current) {
              orderedDocs = orderingService.current.applySavedOrder(
                currentState,
                customOrder.current
              );
            } else {
              orderedDocs =
                orderingService.current.applyDefaultOrder(currentState);
            }
            dispatch(setSuperGroupedDocumentsSlice(orderedDocs));
          }
        });

        unsubscribeList.push(unsubscribe);
      });
    };

    setIsLoadingDocuments(true);
    if (refetch === undefined) fetchAndListen();
    return () => {
      unsubscribeList.forEach((unsubscribe) => unsubscribe());
    };
  }, []);

  const isDocumentsEmpty = (
    _superGroupedDocuments: SuperGroupedDocument[] | null
  ) => {
    if (!_superGroupedDocuments) return true;
    return _superGroupedDocuments.every(
      (superGroup) => superGroup.groups.length === 0
    );
  };
  return {
    groupedDocuments,
    superGroupedDocuments,
    setGroupedDocuments,
    setSuperGroupedDocuments,
    updateCurrentOrder,
    uid: id,
    isDocumentsEmpty,
    visaType,
    isLoadingDocuments
  };
};

export default useDocuments;
