import { Tooltip, TooltipProps } from "@chakra-ui/react";
import { ReactNode, useState } from "react";

interface CustomTooltipProps extends TooltipProps {
  children: ReactNode;
  label: string;
}

const CustomTooltip = ({ children, label, ...props }: CustomTooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tooltip
      label={label}
      isOpen={isOpen}
      hasArrow
      bg="gray.900"
      color="white"
      fontSize="sm"
      fontWeight="medium"
      borderRadius="md"
      py={1}
      px={3}
      placement="top"
      zIndex="10000"
      {...props}
    >
      <span
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        // Prevent focus from opening the tooltip
        onFocus={(e) => e.preventDefault()}
      >
        {children}
      </span>
    </Tooltip>
  );
};

export default CustomTooltip;
