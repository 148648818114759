import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Textarea
} from "@chakra-ui/react";
import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import { useDocumentCategoriesAndTypes } from "../../../hooks/useDocumentCategoriesAndTypes";
import { useGetSubDocuments } from "../../../hooks/useGetSubDocuments";
import { deleteDocument } from "../../../redux/documents/documentSlice";
import { documentSelectors } from "../../../redux/documents/selectors";
import { VisaDocumentType } from "../../../redux/documents/types";
import { DataDocs, visaFromPath } from "../../../types/tables-data";
import { Dropdown } from "../../common/Dropdown";
import { DropdownGeneric } from "../../common/DropdownGeneric";
import { FileExtensionThumbnail } from "../../common/Filethumbnail";
import { SubdocumentsTable } from "./SubdocumentsTable";

interface Props {
  document: DataDocs | undefined;
  isDeleting: boolean;
  isOpen: boolean;
  onClose: () => void;
  headerText: string | undefined;
}

const EditMainDocModal: React.FC<Props> = ({
  document,
  isDeleting,
  isOpen,
  onClose,
  headerText
}) => {
  if (document === undefined) return null;
  const { visaType, id: uid } = useParams();
  const navigate = useNavigate();
  const documentTypes = useSelector(documentSelectors.documentTypes);

  // recator and put this function in a separate hook

  const mappedVisaType = visaFromPath[visaType!];
  const [currentDocument, setCurrentDocument] = useState<DataDocs | undefined>(
    document
  );
  const documentCopy = { ...document };
  const dispatch = useDispatch();
  const [error, setError] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isSuperClassChanged =
    documentCopy.super_class !== currentDocument?.super_class;
  const isCategoryChanged =
    documentCopy.criterion !== currentDocument?.criterion;
  useEffect(() => {
    if (document && isOpen) {
      setCurrentDocument({ ...document });
      setError(undefined);
    }
  }, [document, isOpen]);

  const validate = (): boolean => {
    if (currentDocument?.type === undefined) {
      setError("Document type is required");
      return false;
    }
    setError(undefined);
    return true;
  };

  useEffect(() => {
    validate();
  }, [currentDocument]);

  const handleInputChange = (key: any, value: any) => {
    setCurrentDocument((prevState: any) => {
      // Otherwise, spread the previous state and update the specific key-value pair
      return {
        ...prevState,
        [key]: value,
        // resetting type dropdown
        ...(key === "criterion" && {
          type: undefined
        })
      };
    });
  };

  const handleSubmit = async () => {
    if (!currentDocument?.docRef || !currentDocument || validate() === false) {
      return;
    }

    setIsSubmitting(true);

    const { docUrl, ...documentWithoutUrl } = currentDocument;

    const dataPayload: DataDocs = {
      ...documentWithoutUrl,
      documentTitle: currentDocument?.documentTitle ?? "",
      sub_documents: currentDocument?.sub_documents ?? [],
      isNew: false
    };

    const docRef = doc(db, currentDocument.docRef);
    await updateDoc(docRef, dataPayload).then(() => {
      if (isSuperClassChanged || isCategoryChanged)
        dispatch(deleteDocument(documentCopy));
    });

    setIsSubmitting(false);
    onClose();
  };

  useGetSubDocuments(currentDocument!);

  const { types, reduceCategoriesToSelectedItems } =
    useDocumentCategoriesAndTypes(currentDocument, mappedVisaType);
  const list = reduceCategoriesToSelectedItems(documentTypes, visaType!);

  useEffect(() => {
    if (currentDocument && currentDocument.docRef && currentDocument.isNew) {
      const docRef = doc(db, currentDocument.docRef);
      updateDoc(docRef, { isNew: false });
    }
  }, [currentDocument?.id]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="l"
    >
      <ModalOverlay />
      <ModalContent width="70%">
        <ModalHeader>
          {headerText}
          <div className="float-right">
            <Button
              onClick={() => {
                const { super_class, criterion, id: docId } = currentDocument!;

                const path = `/individual/${uid}/${visaType}/documents/${super_class}/extracted-information?category=${criterion}&docId=${docId}`;
                navigate(path);
              }}
              mr={6}
              variant="primaryOutline"
              px={6}
              leftIcon={<MdOutlineLibraryBooks />}
            >
              View Summary
            </Button>
            <ModalCloseButton
              onClick={onClose}
              display="block"
              position="relative"
              float="right"
              top="unset"
              right="unset"
              bottom="unset"
              m={0}
              p={0}
            />
          </div>
        </ModalHeader>
        <ModalBody pb={6}>
          <Flex flexDirection="column" rowGap={1} width="fit-content">
            <Text my={2} fontWeight={500} fontSize={16}>
              Category
            </Text>
            <DropdownGeneric
              singleSelect
              withArrow
              label={currentDocument?.criterion || "Select Category"}
              getSelectedItem={(item) => {
                if (item !== undefined) {
                  handleInputChange("criterion", item.category);
                  handleInputChange("super_class", item.super_class);
                }
              }}
              groupBy="super_class"
              mergingField="super_class"
              searchField="category"
              list={list}
            />

            <Text my={2} fontWeight={500} fontSize={16}>
              Document Type
            </Text>
            <Dropdown
              selectedValue={currentDocument?.type}
              title="Type"
              onItemSelect={(value) => {
                handleInputChange("type", value);
              }}
              list={types}
            />
            {currentDocument?.type === "Other" && (
              <>
                <Input
                  placeholder="Document title"
                  value={currentDocument?.documentTitle ?? ""}
                  onChange={(e) => {
                    handleInputChange("documentTitle", e.target.value);
                  }}
                />
                {/* {formErrors.documentTitle && (
                  <Text color="red">{formErrors.documentTitle}</Text>
                )} */}
              </>
            )}
            {error && <Text color="red">{error}</Text>}
          </Flex>
          {/* Auto-title */}
          <Flex flexDirection="column" py={2} gap={2}>
            <Text my={2} fontWeight={500}>
              AI-generated title
            </Text>
            <Textarea
              size="lg"
              resize="vertical"
              value={currentDocument?.autoTitle ?? ""}
              onChange={(e) => handleInputChange("autoTitle", e.target.value)}
              name="ai-title"
              id="ai-title"
            />
          </Flex>
          {/* Description */}
          <Flex flexDirection="column" py={2} fontSize={16}>
            <Text my={2} fontWeight={500}>
              Description
            </Text>
            <Textarea
              size="lg"
              resize="vertical"
              placeholder="Please explain why this document is relevant and how it showcases the applicant's accomplishments."
              value={currentDocument?.description ?? ""}
              onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </Flex>
          <Divider my={5} />

          {/*  Sub_documents table  */}
          <div className="">
            <Heading size="md" my={4}>
              Supporting documents
            </Heading>
            {currentDocument && (
              <SubdocumentsTable
                document={currentDocument}
                visaType={currentDocument.documentType as VisaDocumentType}
              />
            )}
          </div>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Box>
            <div className="inline-flex gap-2">
              <div className="w-4 h-4 ">
                <FileExtensionThumbnail doc={document!} />
              </div>
              <span className="">{currentDocument?.documentTitle}</span>
            </div>
            <br />
            <span className="text-xs">document id: {currentDocument?.id}</span>
            <br />
            <small>you can change the category of the document</small>
          </Box>

          <Box>
            <Button
              variant="secondaryOutline"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primaryFilled"
              ml={3}
              isLoading={isSubmitting}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditMainDocModal;
