import { combineReducers } from "redux";
import caseResearchReducer from "./case-research/caseResearchSlice";
import activeClientReducer from "./client-case/activeClientSlice";
import clientsReducer from "./clients/clientsSlice";
import documentReducer from "./documents/documentSlice";
import draftsReducer from "./drafts/draftsSlice";
import extractionReducer from "./extraction-jobs/extractionJobsSlice";
import fileUploadReducer from "./file-upload/fileUploadSlice";
import formsReducer from "./forms/formsSlice";
import siderbarReducer from "./sidebar/sibdarSlice";
import individualReducer from "./individual/individualSlice";
import lawyerReducer from "./lawyer/lawyerSlice";
import templatesReducer from "./templates/templatesSlice";
import promptsReducer from "./prompts/promptsSlice";

const rootReducer = combineReducers({
  lawyer: lawyerReducer,
  clients: clientsReducer,
  activeClient: activeClientReducer,
  individual: individualReducer,
  document: documentReducer,
  extraction: extractionReducer,
  fileUpload: fileUploadReducer,
  caseResearch: caseResearchReducer,
  drafts: draftsReducer,
  forms: formsReducer,
  sidebar: siderbarReducer,
  templates: templatesReducer,
  prompts: promptsReducer
});

export default rootReducer;

export type RootReducer = ReturnType<typeof rootReducer>;
