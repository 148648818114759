import { useEffect, useState } from "react";

import { Divider } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { DataDocs } from "../types/tables-data";
import "./document_slider.css";
import { DocumentDetailsSection } from "./DocumentDetailsSection";
import { DocumentThumbnail } from "./DocumentThumbnail";

const SLIDER_SETTINGS: Settings = {
  speed: 250,
  arrows: true,
  slidesToScroll: 3,
  adaptiveHeight: true,
  infinite: false,
  variableWidth: true,
  vertical: false,
  centerMode: false,
  rows: 1
};

type DocumentsSliderProps = {
  categoryIndex: number;
  documents: DataDocs[];
};

export const DocumentsSlider = ({
  categoryIndex,
  documents
}: DocumentsSliderProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const docIdFromPath = searchParams.get("docId");

  const docIdFromPathIndex = documents.findIndex(
    (document) => document.id === docIdFromPath
  );
  const defaultDocumentIndex =
    docIdFromPathIndex !== -1 ? docIdFromPathIndex : 0;

  const [activeSlide, setActiveSlide] = useState(defaultDocumentIndex);
  const selectedDocument = documents[activeSlide];

  useEffect(() => {
    if (selectedDocument) {
      searchParams.set("category", selectedDocument.criterion!);
      searchParams.set("docId", selectedDocument.id!);
      setSearchParams(searchParams);
    }
  }, [activeSlide, documents]);

  useEffect(() => {
    setActiveSlide(defaultDocumentIndex);
  }, [documents]);

  return (
    <div className="!overflow-visible">
      <Slider {...SLIDER_SETTINGS}>
        {documents.map((document: DataDocs, indx: number) => (
          <DocumentThumbnail
            onClick={() => {
              setActiveSlide(indx);
            }}
            key={document.id}
            indx={`${categoryIndex + 1}.${indx + 1}`}
            document={document}
            visible={activeSlide === indx}
          />
        ))}
      </Slider>
      <Divider my={12} />
      {/* EvidenceItem Preview */}
      {selectedDocument !== undefined && (
        <DocumentDetailsSection
          document={selectedDocument}
          key={selectedDocument.id}
          exhibit={`${categoryIndex + 1}.${activeSlide + 1}`}
        />
      )}
    </div>
  );
};
