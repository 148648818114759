import { WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Tooltip,
  VStack
} from "@chakra-ui/react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import { autoFillQuestionnaireWithDocuments } from "../../../api/OpenAIApi";
import { documentSelectors } from "../../../redux/documents/selectors";
import { DATABASE, DataDocs } from "../../../types/tables-data";
import { DropdownGeneric } from "../../common/DropdownGeneric";
import { AiGenerateIcon } from "../../common/Icons";
import { eb2Docs } from "../../../testTableData";
import { filterActiveDocuments } from "../../../helpers/helpers";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
const AIQuestionnaireModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { id: uid } = useParams();
  const groupedDocuments = useSelector(documentSelectors.groupedDocuments);
  const [allDocuments, setAllDocuments] = useState<DataDocs[]>(
    filterActiveDocuments(groupedDocuments)
  );
  const [previouslySelectedDocuments, setPreviouslyDocuments] = useState<
    DataDocs[]
  >([]);
  const selectedDocuments = useRef<DataDocs[]>([]);
  const [documents, setDocuments] = useState<DataDocs[]>([]);
  const [autoFilling, setAutoFilling] = useState(false);
  const questionnaireDocRef = doc(db, DATABASE.QUESTIONNAIRES, uid!);

  const getUsedDocuments = async () => {
    if (questionnaireDocRef) {
      const document = await getDoc(questionnaireDocRef);
      const data = document.data();
      if (data && "usedDocuments" in data) {
        // setUsedDocuments(data.usedDocuments as string[]);
        const usedDocumentsIds = data.usedDocuments as string[];
        const getUsedDocuments = allDocuments.filter((doc) =>
          usedDocumentsIds.includes(doc.id!)
        );
        setPreviouslyDocuments(getUsedDocuments);
      }
    }
  };
  const updateUsedDocuments = async () => {
    if (questionnaireDocRef) {
      const selectedDocumentsIds = selectedDocuments.current.map(
        (doc) => doc.id
      );
      await updateDoc(questionnaireDocRef, {
        usedDocuments: selectedDocumentsIds
      });
    }
  };

  useEffect(() => {
    getUsedDocuments();
  }, []);

  const handleAutoFill = async () => {
    setAutoFilling(true);
    autoFillQuestionnaireWithDocuments(selectedDocuments.current, uid!);
    onClose();

    setAutoFilling(false);

    await updateUsedDocuments();
    await getUsedDocuments();
  };

  useEffect(() => {
    setAllDocuments(filterActiveDocuments(groupedDocuments));
  }, [groupedDocuments]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textColor="text.color">
          Generate Questionnaires Using AI
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Warning Section */}
          <Box bg="errors.red2" borderRadius="md" p={4} mb={4}>
            <div className="flex justify-center items-center gap-4">
              <WarningIcon fill="white" color="red" boxSize={5} />
              <div className="flex flex-col">
                <Text fontWeight="bold" color="text.color" mb={2}>
                  Questionnaire Regeneration
                </Text>
                <Text color="text.color" fontWeight={400}>
                  Selecting a new document will overwrite current answers and
                  remove any prior edits to the questionnaire.
                </Text>
              </div>
            </div>
          </Box>

          {/* Dropdown Section */}
          <Text mb={2}>Choose a document to pre-fill the questionnaire</Text>
          <Flex w="100%">
            {allDocuments ? (
              <DropdownGeneric
                list={allDocuments}
                mergingField="id"
                searchField="autoTitle"
                groupBy="super_class"
                checkedItems={previouslySelectedDocuments.map((doc) => doc.id!)}
                getSelectedItems={(selectedItems) => {
                  selectedDocuments.current = selectedItems;
                  setDocuments(selectedItems);
                }}
                singleSelect={false}
                withCheck
                withArrow
                withFilter
                isDisabled={autoFilling}
              >
                {({ derivedList, handleSelect }) => (
                  <VStack
                    align="stretch"
                    spacing={3}
                    maxH="md"
                    overflowY="auto"
                  >
                    {derivedList
                      .filter((item) => item.checked)
                      .map((item, i) => (
                        <Flex key={item.id} align="flex-start" width="100%">
                          <Tooltip label={item.value}>
                            <Tag
                              size="lg"
                              variant="brand"
                              maxW="600px"
                              overflow="hidden"
                            >
                              <TagLabel>{item.value}</TagLabel>
                              <TagCloseButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSelect(item);
                                }}
                              />
                            </Tag>
                          </Tooltip>
                        </Flex>
                      ))}
                  </VStack>
                )}
              </DropdownGeneric>
            ) : (
              <Skeleton />
            )}
          </Flex>
        </ModalBody>

        <ModalFooter>
          {/* Buttons */}
          <Button mr={3} onClick={onClose} variant="secondaryOutline">
            Cancel
          </Button>
          <Button
            variant="primaryFilled"
            isDisabled={documents.length === 0}
            isLoading={autoFilling}
            leftIcon={<Box as={AiGenerateIcon} boxSize={6} fill="white" />}
            onClick={(e) => handleAutoFill()}
          >
            Re-Generate with AI
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AIQuestionnaireModal;
