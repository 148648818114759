import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Heading,
  Spacer,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { doc, updateDoc } from "firebase/firestore";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { Editor } from "../components/common/Editor";
import DocumentInformation from "../components/individualTabs/individualDocuments/DocumentInformation";
import EditMainDocModal from "../components/individualTabs/individualDocuments/EditMainDocModal";
import { updateDocument } from "../redux/documents/documentSlice";
import { DataDocs } from "../types/tables-data";
import DocumentPreviewCard from "./DocumentPreviewCard";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";

type Props = {
  exhibit: string;
  document: DataDocs;
};

export const DocumentDetailsSection: React.FC<Props> = ({
  exhibit,
  document
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { super_class, documentType, docRef } = document;
  const isEvidence = docRef?.split("/").includes("evidence_docs");

  const [documentToEdit, setDocumentToEdit] = useState<DataDocs | undefined>();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose
  } = useDisclosure();

  const panelRefs = useRef<
    Record<string, HTMLButtonElement | HTMLDivElement | null>
  >({});

  useEffect(() => {
    if (documentToEdit) {
      onEditOpen();
    }
  }, [documentToEdit, onEditOpen]);

  const handleAccordionToggle = (panelKey: string, isOpen: boolean) => {
    const editorElement = window.document.querySelector(".ql-editor");
    if (editorElement) {
      /* eslint no-underscore-dangle: 0 */
      const quillInstance = (editorElement.parentNode as any)?.__quill;
      if (quillInstance) {
        // hacky way to blur the quill editor
        // will be implemented in a better way along with the refactor in CB-2010
        quillInstance.focus();
        quillInstance.blur();
      }
    }

    if (!isOpen) {
      if (panelRefs.current[panelKey]) {
        _.debounce(() => {
          panelRefs.current[panelKey]?.scrollIntoView({
            behavior: "smooth",
            block: "start"
          });
        }, 200)();
      }
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const newQueryParameters: URLSearchParams = new URLSearchParams();

  useEffect(() => {
    // implement the params logic here
    if (
      document &&
      !searchParams.has("category") &&
      !searchParams.has("docId")
    ) {
      newQueryParameters.set("category", document.criterion!);
      newQueryParameters.set("docId", document.id!);
      setSearchParams(newQueryParameters);
    }
  }, []);

  return (
    <Box className="h-full !overflow-visible ">
      <SpecialHeading
        title="Edit Document Details"
        fontSize="24px"
        fontWeight={600}
        mb={4}
      />

      {document ? (
        <Box
          key={document.uid}
          className=" rounded-md my-2 h-full !overflow-visible"
        >
          <Text fontWeight="500" textAlign="left" fontSize="14" mb={2}>
            EXHIBIT {exhibit}
          </Text>
          <Accordion
            defaultIndex={[2]}
            allowMultiple
            gap={2}
            overflow="visible"
          >
            {/* Document basic information */}

            <AccordionItem overflow="visible">
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    ref={(el) => {
                      panelRefs.current.documentInfo = el;
                    }}
                    onClick={() => {
                      handleAccordionToggle("documentInfo", isExpanded);
                    }}
                  >
                    <AccordionIcon />
                    Document information
                  </AccordionButton>
                  <AccordionPanel>
                    <DocumentInformation document={document} />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <Divider my={2} visibility="hidden" />

            {/* Additional information */}
            <AccordionItem overflow="visible">
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    onClick={() => {
                      handleAccordionToggle("additionalInfo", isExpanded);
                    }}
                  >
                    <AccordionIcon />
                    Additional information
                  </AccordionButton>
                  <AccordionPanel
                    ref={(el) => {
                      panelRefs.current.additionalInfo = el;
                    }}
                  >
                    <DocumentPreviewCard document={document} />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <Divider my={2} visibility="hidden" />

            {/* AI generated Summary */}
            <AccordionItem overflow="visible" style={{ overflow: "visible" }}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    overflow="visible"
                    onClick={() => {
                      handleAccordionToggle("summary", isExpanded);
                    }}
                  >
                    <AccordionIcon />
                    AI-generated Summary
                  </AccordionButton>
                  <AccordionPanel
                    style={{ overflow: "visible" }}
                    ref={(el) => {
                      panelRefs.current.summary = el;
                    }}
                  >
                    <Box className="h-full">
                      {document?.summaryHtml && (
                        <Editor
                          customHeight="auto"
                          saveText={async (html, text) => {
                            if (!document.docRef) return;

                            setIsSaving(true);
                            const docRef = doc(db, document?.docRef || "");
                            await updateDoc(docRef, {
                              summary: text,
                              summaryHtml: html
                            });
                            dispatch(
                              updateDocument({
                                ...document,
                                summary: text,
                                summaryHtml: html
                              })
                            );

                            setIsSaving(false);
                          }}
                          text={document?.summaryHtml}
                        />
                      )}
                    </Box>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>

          {documentToEdit !== undefined && (
            <EditMainDocModal
              isDeleting={false}
              document={documentToEdit}
              headerText="Edit Main Document"
              isOpen={isEditOpen}
              onClose={onEditClose}
            />
          )}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};
